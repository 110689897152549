@import '../styles/common/media-queries.scss';
@import '../styles/common/rtl.scss';

.footer{
  .footer_container{
    .grid_container{

      .grid_logo{
        .contents_logo{
          display: flex;
          justify-content: center;
          .logo_wr{
            width: 119px;
            display: flex;
            cursor: pointer;
            margin:30px 0;
            @include res-down-high(sm){
              margin: 1rem 0;
            }
            @include res-down-high(sm){
              width: 119px;
              height: 47px;
            }
            .logo{
              width: 119px;
              height: auto;
              display: block;
              @include res-down-high(sm){
                width: 170px;
                height: 50px;
              }
            }
          }
        }
      }
      .grid_info{
        .title{
          display: flex;
          flex-direction: column;
          font-size: 24px;
          line-height: 1.2;
          color: #000;
          font-weight: 300;
          @include res-down-high(sm){
            font-size: 20px;
            text-align: center;
          }
          a{
            text-decoration: underline;
            color: #000;  
          }
        }
        .desc{
          display: flex;
          flex-direction: row;
          font-size: 18px;
          color: #000;
          align-items: center;
          @include res-down-high(sm){
            text-align: center;
            justify-content: center;
            align-items: center;
          }
          span{
            display: flex;
            @include rtl(margin,0 10px 0 0 ,0 0 0 10px);
          }
        }

      }
      .list{
        span{
          font-weight: 300;
        }
      }
      .grid_sitemap_a{
        display: flex;
        justify-content: center;
        @include res-down-high(sm){
          flex-direction: column;
        }
        span{
          @include res-down-high(sm){
            text-align: center;
            font-size: 15px;
          }
        }
      }
      .grid_sitemap_b{
        display: flex;
        justify-content: flex-end;
        @include res-down-high(sm){
          flex-direction: column;
        }
        span{
          @include res-down-high(sm){
            text-align: center;
            font-size: 15px;
          }
        }
      }
      .grid_copyright{
        padding: 35px 0;
        @include res-down-high(sm){
          padding: 1rem 0;
        }
        .copyright{
          margin: 0;
          @include rtl(direction, unset, ltr);
          @include rtl(text-align, left, right);
          @include res-down-high(sm){
            text-align: center;
          }
          small{
            font-size: 16px;
            color: #000;
            font-weight: 300;
              @include res-down-high(sm){
                text-align: center;
                font-size: 15px;
              }
          }
        }
      }

    }
  }
  .footer_copyright__J4r4F{
    display: none;
  }
}


.scroll_to_top{
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  .scroll_to_top_icon_wr{
    min-width: unset;
    @include rtl(margin,0 0 0 10px ,0 10px 0 0);
    
  }
}

