@import '../../styles/common/colors.scss';
@import '../../styles/common/media-queries.scss';
@import '../../styles/common/rtl.scss';

.article{
  display: flex;
  flex-direction: column;
  padding-bottom: 35px;
  width: 100%;
  @include res-down-high(sm){
    padding-bottom: 1rem;
  }
  .content_wr{
    position: relative;
    .video_wr{
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin:0;
      max-height: 75vh;
      min-height: 675px;
      overflow: hidden;
      @include res-down-high(sm){
        min-height: unset;
        max-height: unset;
      }
      video{
        width: 114%;
        // height: 660px;
        object-fit: cover;
        @include res-down-high(sm){
          max-height: unset;
          height: 100%;
          width: 225%;
          object-fit: cover;
        }
        @media (min-height: 800px) and (orientation: portrait) {
          height:calc(100vh - 400px)
        }
      }
      img{
        // max-width: 100%;
        width: 100%;
        @include res-down-high(sm){
          max-height: calc(100vh - 180px);
          height: auto;
          width: 100%;
          object-fit: cover;
        }
        @media (min-height: 800px) and (orientation: portrait) {
          height:calc(100vh - 400px)
        }
      }
      .btn_register{
        position: absolute;
        bottom: 50px;
        left: 48px;
        right: 48px;
        width: calc(100% - 96px);
        // box-shadow: 0 0 20px 5px rgba(255,255,255,75%);
        
      }
      figcaption{
        text-align: center;
        margin-top: 1rem;
      }
    }
    .context_wr{
      position: absolute;
      top:0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      background: rgb(0 0 0 / 20%);
      .context{
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        .title_wr{
          margin-bottom: 32px;
          width: 39.027777777778vw;
          // width: 562px;
          // height: 188px;
          @include res-down-high(sm){
            width:43.125vw;
            margin-bottom: 12px;
          }      
        }
        .image_title{
          margin-bottom: 140px;
          @include res-down-high(sm){
            margin-bottom: 50px;
          }
        }
        
        .btn_play{
          border:2px solid $brand;
          width: 125px;
          height: 125px;
          color:$brand;
          font-size: 65px;
          margin:40px 0 75px;
          @include res-down-high(sm){
            width: 45px;
            height: 45px;
            font-size: 16px;
            margin: 10px 0 20px;
          }
        }
        .btn_scroll{
          margin-bottom: 40px;
          @include res-down-high(sm){
            margin-bottom: 10px;
            padding-top: 0;
            padding-bottom: 0;
            svg{
              height: 28px;
              width: 8px;
              >g{
                transform: scale(.5);
              }
            }
          }
        }
      }
    }
   
    .motion_div{
      position: absolute;
      width: 100%;
      height: 100%;
      left:0;
      top:0;
    
      .react_player{

      }
    }
      
  }
  
}


