@import '../../styles/common/colors.scss';
@import '../../styles/common/media-queries.scss';
@import '../../styles/common/rtl.scss';

.form{
  .fields_wr{
    .field_wr{
      margin-bottom: 1rem;
      display: flex;
      justify-content: center;
      &:last-child{
        margin-bottom: 0;
      }
      .icon_wr{
        color:$brand;
      }
      .textarea{
        >div{
          align-items: flex-start;
        }
        .icon_wr{
          height: auto;
        }
      }
    .btn_min{
      border-color: $brand;
    }
    }
  }
}
