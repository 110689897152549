$brand:#fdaf19;
$accent:#0E2D6D;
$brand-light:#C8F1FF;
$brand-dark:#2999BF;

$gradient-en:linear-gradient(90deg, #E21A74 0%, #497ABE 52%, #1997D5 68%, #00A7E1 100%);
$gradient-ar:linear-gradient(90deg, #00A7E1 0%, #1997D5 32%, #497ABE 48%, #E21A74 100%);
$gradient-dashboard-en:linear-gradient( 90deg , rgba(226,26,116,0.11) 0%, rgba(0,167,225,0.11) 76%);
$gradient-dashboard-ar:linear-gradient(90deg, rgba(0,167,225,0.11) 24%, rgba(226,26,116,0.11) 100%);

$error:#f44336;
$success:#00e676;

$graph-stop:#D30000;
$graph-pending:#FF943E;
$graph-success:#26C327;

//default -------------------------
$bg-white:#fff;
$bg-shade:#F2F2F2;
//text
$text-default:#3C3C3C;
$text-light:#9D9D9D;
$text-white:#fff;
//svg
$fill-white:#fff;
$svg-caret-down-white:url("~assets/images/icons/caret-down.svg");
//border
$border:#C5CAC8;
$border-white:#fff;
$border-default: #949494;
$border-active:#6F7271;

//dark -------------------------
$bg-black:#121212;
$bg-dark:#121212;
$bg-shade:#F2F2F2;
//text
$text-default-dark:#f1f1f1;
$text-light:#9D9D9D;
$text-dark:#121212;
//svg
$fill-dark:#121212;
$svg-caret-down-dark:url("~assets/images/icons/caret-down-dark.svg");
//border
$border:#C5CAC8;
$border-white:#fff;
$border-default: #949494;
$border-active:#6F7271;

$themes: (
  theme-light: (
    'bg-color': $bg-white,
    'text-default': $text-default,
    'text-white': $text-white,
    'caret-down': $svg-caret-down-white,
    'fill': $fill-white,

    //unique
    'caret-down-nav': $svg-caret-down-white,
    'app-nav-color': $brand-light,
  ),
  theme-dark: (
    'bg-color': $bg-dark,
    'text-default': $text-default-dark,
    'text-white': $text-dark,
    'caret-down': $svg-caret-down-dark,
    'fill': $fill-dark,

    //unique
    'caret-down-nav': $svg-caret-down-white,
    'app-nav-color': $text-default,
  )
);

// @mixin theme() {
//   @each $theme, $map in $themes {
//     $theme-map: $map !global;
//     .#{$theme} & {
//       @content;
//     }
//   }
//   $theme-map: null !global;
// }

// @function t($key) {
//   @return map-get($theme-map, $key);
// }


$trans: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

@mixin ease {
  transition: $trans;
}