@import '../styles/common/media-queries.scss';
@import '../styles/common/rtl.scss';
@import '../styles/common/colors.scss';

.main{
  min-height: calc(100vh - 77px);
  display: flex;
  align-items: center;
  flex-direction: column;
  .article{
    display: flex;
    flex-direction: column;
    width: 100vw;
    .heroimg{
      display: flex;
      margin: 0 0 25px;
      position: relative;
      flex-direction: column;
      video{
        width: 100%;
        @include res-down-high(sm){
          max-height: calc(100vh - 180px);
          height: auto;
          width: 100%;
          object-fit: cover;
        }
        @media (min-height: 800px) and (orientation: portrait) {
          height:calc(100vh - 400px)
        }
      }
      img{
        // max-width: 100%;
        width: 100%;
        @include res-down-high(sm){
          max-height: calc(100vh - 180px);
          height: auto;
          width: 100%;
          object-fit: cover;
        }
        @media (min-height: 800px) and (orientation: portrait) {
          height:calc(100vh - 400px)
        }
      }
      .btn_register{
        position: absolute;
        bottom: 50px;
        left: 48px;
        right: 48px;
        width: calc(100% - 96px);
        // box-shadow: 0 0 20px 5px rgba(255,255,255,75%);
      }
      figcaption{
        text-align: center;
        margin-top: 1rem;
      }
    }
    .heroimg_fig{
      display: flex;
      margin: 0 0 1rem;
      position: relative;
      flex-direction: column;
      img{
        // max-width: 100%;
        width: 100%;
        @include res-down-high(sm){
          max-height: calc(100vh - 180px);
          height: auto;
          width: 100%;
          object-fit: cover;
        }
        @media (min-height: 800px) and (orientation: portrait) {
          height:calc(100vh - 400px)
        }
      }
      figcaption{
        text-align: center;
        margin-top: 1rem;
      }
    }
    .timer{
      margin: 0 0 25px 0;
      padding: 0;
      >dt,.dt{
        margin: 0;
        padding: 0;
      }
      >dd{
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-around;
        .time{
          margin: 10px 0 0 0;
          padding: 0;
          justify-content: center;
          text-align: center;
          dt{
            font-weight: 600;
            font-size: 40px;
            line-height: 1;
            color: #000;
            padding: 0;
            margin-bottom: 15px;
          }
          dd{
            font-weight: 300;
            font-size: 10px;
            line-height: 1;
            color: #000;
            margin: 0;
            padding: 0;
          }
        }
        
      }
      
    }
    .select_wr{
      .select{
        margin-bottom: 10px;
      }
    }
    .feat_slider{
      .feat_slide_img{
        display: flex !important;
      }
      .slick_back{
        svg{
          // @include rtlOnly(transform, rotateY(180deg));
        }
        @include rtl(left,13px ,13px);
        @include rtl(right,unset ,unset);
        display: flex !important;
        position: absolute;
        z-index: 9;
        color:#000;
        background-color: #fff;
        width: 48px;
        height: 48px;
        transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        &:before{
          display: none;
        }
        &:hover,&:focus{
          color:#fff;
          background-color: #000;
        }
      }
      .slick_next{
        svg{
          // @include rtlOnly(transform, rotateY(180deg));
        }
        @include rtl(right,13px ,13px);
        @include rtl(left,unset ,unset );
        display: flex !important;
        position: absolute;
        z-index: 9;
        color:#000;
        background-color: #fff;
        width: 48px;
        height: 48px;
        transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        &:before{
          display: none;
        }
        &:hover,&:focus{
          color:#fff;
          background-color: #000;
        }
      }
    }
    .iframe_wr{
      width: 100%;
      height: calc(100vh - 80px);
      margin-bottom: 2rem;
      iframe{
        width: 100%;
        height: calc(100vh - 80px);
      }
    }
  }
  
  .section{
    display: flex;
    flex-direction: column;
    width: 100vw;
    .header{
      padding: 35px 0;
      @include res-down-high(sm){
        padding: 1rem 0;
      }
      .grid_item{
        position: relative;
        &:before,&:after{
          content:"";
          background-color: #707070;
          width: 75px;
          height: 2px;
          display: block;
          position: absolute;
          top:0;
          bottom: 0;
          margin: auto;
          @include res-down-high(sm){
            display: none;
          }
        }
        &:before{
          left:-115px;
        }
        &:after{
          right: -115px;
        }
        .header_title{
          font-size: 36px;
          color: #000;
          text-align: center;
          line-height: 1.2;
          margin: 0;
          display: flex;
          flex-direction: column;
          @include res-down-high(sm){
            font-size: 21px;
          }
          .text_1{
            font-weight: 300;
          }
          .text_2{
            font-weight: 700;
          }
        }
        .header_desc{
          font-size: 24px;
          color: #000;
          font-weight: 300;
          text-align: center;
          line-height: 1.2;
          margin: 0;
          @include res-down-high(sm){
            font-size: 12px;
          }
        }
      }
    }
    .article_img{
      display: flex;
      flex-direction: column;
      width: 100vw;
      padding-top:35px;
      padding-bottom:35px;
      @include res-down-high(sm){
        padding-top:1rem;
        padding-bottom:1rem; 
      }
      .grid_container{
        align-items: center;
        .grid_img{
  
        }
        .grid_context{
          .context_wr{
            padding:30px 75px;
            min-height: 360px;
            width: 130%;
            display: flex;
            flex-direction: column;
            position: relative;
            justify-content: center;
            @include res-down-high(sm){
              width: 90%;
              padding: 35px 2rem 0;
              margin: -75px auto 0;
              min-height: 150px;
            }
            &:before{
              content:"";
              position: absolute;
              background:#fff;
              width: 100%;
              height: 100%;
              top:0;
              left: 0;
              opacity: 0.95;
              filter: blur(10px);
            }
            .title{
              font-size: 48px;
              color: #000;
              line-height: 1.2;
              margin: 0;
              z-index: 1;
              display: flex;
              flex-direction: column;
              text-transform: capitalize;
              @include res-down-high(sm){
                font-size: 30px;
                text-align: center;
              }
              .text_1{
                font-weight: 300;
              }
              .text_2{
                font-weight: 700;
              }
            }
            div{
              z-index: 1;
            }
            p,.desc{
              font-family: NotoSans;
              font-size: 16px;
              color: #000;
              line-height: 1.2;
              z-index: 1;
              font-weight: 300;
              @include res-down-high(sm){
                font-size: 15px;
                text-align: center;
              }
              &:last-child{
                margin-bottom: 0;
              }
            }
          }
        }
      }
      &:nth-child(even){
        .grid_container{
          .grid_context{
            .context_wr{
              @include rtl(left,-150px ,unset);
              @include rtl(right,unset ,-150px);
              @include res-down-high(sm){
                @include rtl(left,unset, unset);
                @include rtl(right,unset, unset);
              }
            }
          }
        }
      }
      &:nth-child(odd){
        .grid_container{
          flex-direction: row-reverse;
          .grid_context{
            .context_wr{
  
            }
          }
        }
      }
    }
  }
  .article_feature_benefits{
    padding-top:35px;
    padding-bottom:35px;
    @include res-down-high(sm){
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .grid_container{
      .grid_img{
        @include res-down-high(sm){
          padding-bottom: 2rem;
        }
      }
      .grid_context{
        .title{
          font-size: 48px;
          color: #000;
          line-height: 1.2;
          margin: 0 0 10px;
          z-index: 1;
          display: flex;
          flex-direction: column;
          @include res-down-high(sm){
            font-size: 30px;
            text-align: center;
          }
          .text_1{
            font-weight: 300;
          }
          .text_2{
            font-weight: 700;
          }
        }
        .list{
          .list_item{
            padding-left: 0;
            padding-right: 0;
            .list_item_icon{
              @include rtl(margin,0 22px 0 0 , 0 0 0 22px);
              @include res-down-high(sm){
                @include rtl(margin,0 7px 0 0 , 0 0 0 7px);
                min-width: 1.875rem;
              }
              .list_icon{
                color:$brand;
                font-size: 50px;
                @include res-down-high(sm){
                  font-size: 30px;
                }
                >path{
                  background:#000;
                }
              }
            }
            .list_item_text{
              span{
                font-size: 23px;
                color: #000;
                font-weight: 300;
                @include res-down-high(sm){
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
  .article_textures{
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding-top: 35px;
    padding-bottom: 35px;
    .header{
      padding: 0 0 40px;
      .grid_item{
        position: relative;
        &:before,&:after{
          content:"";
          background-color: #707070;
          width: 75px;
          height: 2px;
          display: block;
          position: absolute;
          top:0;
          bottom: 0;
          margin: auto;
          @include res-down-high(sm){
            display: none;
          }
        }
        &:before{
          left:-115px;
        }
        &:after{
          right: -115px;
        }
        .header_title{
          font-size: 36px;
          color: #000;
          text-align: center;
          line-height: 1.2;
          margin: 0;
          display: flex;
          flex-direction: column;
          @include res-down-high(sm){
            font-size: 1.875rem;
          }
          .text_1{
            font-weight: 300;
          }
          .text_2{
            font-weight: 700;
          }
        }
        .header_desc{
          font-size: 24px;
          color: #000;
          font-weight: 300;
          text-align: center;
          line-height: 1.2;
          margin: 0;
        }
      }
    }
    .grid_ul{
      list-style: none;
      padding: 0;
      margin-bottom: 0;
    }
  }

  .section_informations{
    padding-top: 35px;
    padding-bottom: 70px;
    @include res-down-high(sm){
      padding-top: 1rem;
      padding-bottom: 2rem;
    }
    .title{
      font-size: 48px;
      font-weight: 300;
      color: #000;
      line-height: 1.1;
      margin-bottom: 40px;
      @include res-down-high(sm){
        font-size: 30px;
        text-align: center;
        margin-bottom: 2rem;
      }
    }
    .grid_form{
      @include res-down-high(sm){
        margin-bottom: 35px;
      }
    }
    .grid_datasheets{
      .title{
        margin-bottom: 1rem;
      }
      .list_download{
        .list_item{
          padding: 12px 0;
          border-bottom: 1px solid $brand;
          margin-bottom: 10px;
          &:last-child{
            margin-bottom: 0;
          }
          .list_label{
            span{
              font-size: 16px;
              color: #000;
              font-weight: 300;
              @include res-down-high(sm){
                font-size: 15px;
              }
            }
          }
          .list_link{
            .list_download{
              span{
                font-size: 16px;
                color: #000;
                font-weight: 300;
                @include res-down-high(sm){
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
      :global .list_watch {
        li.MuiListItem-root {
          padding: 12px 0;
          span {
            font-size: 16px;
            color: #000;
            font-weight: 300;
        }
      }
      .list_link{
        cursor: pointer;
      }
      }
    }
    
  }

  .article_discover_products{
    background-color: #F7F7F7;
    padding: 0 5rem 50px;
    @include res-down-high(sm){
      padding: 0 1rem 10px;
    }
    margin: 35px 0 0;
    header{
      .header_title{
        font-size: 36px;
        color: #000;
        text-align: center;
        line-height: 1.2;
        margin: 50px 0;
        display: flex;
        flex-direction: column;
        @include res-down-high(sm){
          font-size: 21px;
        }
        .text_1{
          font-weight: 300;
        }
        .text_2{
          font-weight: 700;
        }
      }
    }
    .context_wr{
      .title{
        font-size: 25px;
        color: #222B45;
        line-height: 1.2;
        margin: 0;
        z-index: 1;
        @include res-down-high(sm){
          font-size: 20px;
          text-align: center;
        }
        
        .text_1{
          font-weight: 300;
        }
        .text_2{
          font-weight: 700;
          margin: 20px 0;
          display: block;
        }
        
      }
      p.title{
        font-size: 15px;
        color: #222b4582;
        min-height: 90px;
        @include res-down-high(sm){
          min-height: unset;
        }
      }
      ul{
        list-style: none;
        padding: 0;
        margin: 0 0 10px;
        @include rtlOnly(text-align, right);
        @include res-down-high(sm){
          margin: 0 0 50px;
        }
        li{
          display: flex;
          align-items: center;
          @include rtlOnly(flex-direction, row-reverse);
          svg{
            @include rtl(margin, 0 5px 0 0, 0 0 0 5px);
          }
          span{
            font-size: 12px;
            font-weight: 300;
            color: #222B45;
            font-family: 'PlayfairDisplay';
          }
        }
      }
      
    }
    :global .context{
      position: absolute;
      bottom: 10px;
      .btn_play{
        width: 30px !important;
        height: 30px !important;
        font-size:15px !important;
      }
    }
    :global .img_caption{
      img{
        object-fit: contain;
      }
    }
    :global figcaption{
      display: none !important;
    }
    :global .product-item {
      padding: 0 1rem;
    }
    :global .product-item h4 {
      margin: 10px 0 0;
      @include rtlOnly(text-align, right);
    }    
    :global .product-item p {
      font-family: NotoSans;
      font-size: 15px;
      font-weight: 300;
      line-height: 1.2;
      color: #222b4582;
      @include rtlOnly(text-align, right);
    }
    :global .slick-slider {
      .slick-slide{
        background-color: transparent;
        > div{
          padding: 10px;
          .feature-item{
            border-radius: 4px;
            box-shadow: 0 0 2px #00276557;
            padding: 1rem 10px;
            h3{
              font-size: 16px;
            }
          }
        }
        
    }
    .slick-prev{
      @include res-down-high(sm){
        left: 0px;
        top:unset;
        bottom: 50%;
      }
      &:before{
        color:#000;
      }
    }
    .slick-next{
      @include res-down-high(sm){
        right: 0px;
        top:unset;
        bottom: 50%;
      }
      &:before{
        color:#000;
      }
    }
      }
  }
  .article_ind_sectors{
    padding: 0 5rem 50px;
    @include res-down-high(sm){
      padding: 0 1rem 10px;
    }
    margin: 35px auto 0;
     
    .context_wr{
      .title{
        font-size: 25px;
        color: #222B45;
        line-height: 1.2;
        margin: 0;
        z-index: 1;
        @include res-down-high(sm){
          font-size: 20px;
          text-align: center;
        }
        
        .text_1{
          font-weight: 300;
        }
        .text_2{
          font-weight: 700;
          margin: 20px 0;
          display: block;
        }
        
      }
      p.title{
        font-size: 15px;
        color: #222b4582;
        min-height: 90px;
        @include res-down-high(sm){
          min-height: unset;
        }
      }
       
      
    }
    :global .context{
      position: absolute;
      bottom: 10px;
      .btn_play{
        width: 30px !important;
        height: 30px !important;
        font-size:15px !important;
      }
    }
    :global .img_caption{
      img{
        object-fit: contain;
      }
    }
    :global figcaption{
      display: none !important;
    }
    :global .product-item {
      padding: 0 1rem;
    }
    :global .product-item h4 {
      margin: 10px 0 0;
      @include rtlOnly(text-align, right);
    }    
    :global .product-item p {
      font-family: NotoSans;
      font-size: 15px;
      font-weight: 300;
      line-height: 1.2;
      color: #222b4582;
    }
    :global .slick-slider {
      .slick-slide{
        background-color: transparent;
        > div{
          padding: 10px;
          .feature-item{
            border-radius: 4px;
            box-shadow: 0 0 2px #00276557;
            padding: 1rem 10px;
            h3{
              font-size: 16px;
            }
          }
        }
        
    }
    .slick-prev{
      @include res-down-high(sm){
        left: 0px;
        top:unset;
        bottom: 50%;
      }
      &:before{
        color:#000;
      }
    }
    .slick-next{
      @include res-down-high(sm){
        right: 0px;
        top:unset;
        bottom: 50%;
      }
      &:before{
        color:#000;
      }
    }
      }
  }
  .article_educational_video,
  .article_product_application{
    .discover_video{
      margin: 0;
      max-width: 100%;
      
      video{
        aspect-ratio: 16/9;
        object-fit: fill !important;
        &:-webkit-full-screen{
          object-fit: fill !important;
        }
      }
      :global .context{
        .btn_play{
          width: 50px !important;
          height: 50px !important;
          font-size:30px !important;
        }
      }
      :global .img_caption{
        height: 100vh;
        top: 0;
        @include res-down-high(md){
          height: auto;
        }
        img{
          object-fit: contain;
        }
      }
      :global figcaption{
        display: none !important;
      }
    }
  }
.article_product_application{
  header{
    h2{
      font-size: 36px;
      color: #000;
      text-align: center;
      line-height: 1.2;
      margin: 0px 0 50px;
    }
  }
}
 .article_features_slider{
  text-align: center;
  background-color: #fff;
  padding: 0 2rem 50px;
  img{
    margin: 0 auto;
  }
  .header_title{
    font-size: 36px;
    color: #000;
    text-align: center;
    line-height: 1.2;
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    @include res-down-high(sm){
      font-size: 21px;
    }
    .text_1{
      font-weight: 300;
    }
    .text_2{
      font-weight: 700;
    }
  }
  :global .slick-slider {
    .slick-slide{
      background-color: transparent;
      > div{
        padding: 10px;
        .feature-item{
          border-radius: 4px;
          box-shadow: 0 0 2px #00276557;
          padding: 1rem 10px;
          h3{
            font-size: 16px;
            @include res-down-high(sd){
              font-size: 14px;
            }
            @include res-down-high(mdl){
              font-size: 13px;
            }
            @include res-down-high(md){
              font-size: 12px;
            }
            @include res-down-high(sm){
              font-size: 15px;
            }
          }
        }
      }
      
  }
  .slick-prev{
    @include res-down-high(sm){
      left: 10px;
      top:unset;
      bottom: -50px;
    }
    &:before{
      color:#000;
    }
  }
  .slick-next{
    @include res-down-high(sm){
      right: 10px;
      top:unset;
      bottom: -50px;
    }
    &:before{
      color:#000;
    }
  }
    }
 }
 :global .jotafloor_informations{
  padding-bottom: 0;
}
}

 
